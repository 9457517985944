import { tns } from "tiny-slider/src/tiny-slider.module";

if (document.querySelector(".slide-wrapper")) {
    var slider0 = tns({
        container: ".slide-wrapper",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev")) {
        document.querySelector(".prev").onclick = function () {
            slider0.goTo("prev");
        };
    }

    if (document.querySelector(".next")) {
        document.querySelector(".next").onclick = function () {
            slider0.goTo("next");
        };
    }
}


if (document.querySelector(".slide-wrapper-about")) {
    var slider1 = tns({
        container: ".slide-wrapper-about",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            991: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prevAbout")) {
        document.querySelector(".prevAbout").onclick = function () {
            slider1.goTo("prev");
        };
    }

    if (document.querySelector(".nextAbout")) {
        document.querySelector(".nextAbout").onclick = function () {
            slider1.goTo("next");
        };
    }
}
